/* App.css */
@import url('https://fonts.googleapis.com/css2?family=Orbitron&display=swap');

.App {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: #e9546b;
  padding: 0.5em;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: absolute;
  top: 5px;
  padding: 20px;
}

.title {
  font-family: 'Orbitron', sans-serif;
  font-size: calc(20px + 3vmin);
  margin-top: 70px;
  color: #00a381;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.title {
  margin-right: auto;
}

.version {
  margin-left: 20px;
  margin-bottom: 5px;
  cursor: pointer;
  text-decoration: underline;
  color: #d37684;
}

.mode {
  font-family: 'Orbitron', sans-serif;
  font-size: calc(5px + 3vmin);
  color: #895b8a;
  margin-left: 10px;
}

.reelContainer {
  display: flex;
  justify-content: space-around;
  flex-wrap: nowrap;
  max-width: 95%;
  overflow: hidden;
}

.reel {
  font-family: 'Orbitron', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 40vw;
  width: 20vw;
  border: 3px solid #89c3eb;
  padding: 5px 0.5em;
  margin: 0.5em;
}

.symbol {
  font-size: calc(20px + 5vmin);
}

button {
  font-family: 'Orbitron', sans-serif;
  font-size: calc(10px + 4vmin);
  background-color: #89c3eb;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  margin-top: 20px;
  width: 100%;
  height: calc(10px + 7vmin);
  padding: 5px 0;
}

button:hover {
  background-color: #2ca9e1;
}

.lamp {
  font-family: 'Orbitron', sans-serif;
  font-size: 7vmin;
  transition: color 0.5s ease;
  margin-top: 30px;
  height: 50%;
}

.lamp.unlit {
  color: #555;
}

.lamp.lit {
  color: #61dafb;
}

.newMedals {
  font-family: 'Orbitron', sans-serif;
  font-size: 5vmin;
  transition: opacity 2s ease-in-out;
  margin-top: 30px;
  color: #ffec47;
}

.newMedals.invisible {
  opacity: 0;
}

.newMedals.visible {
  opacity: 1;
}

.totalAndSpinCount {
  font-family: 'Orbitron', sans-serif;
  font-size: calc(5px + 3vmin);
  color: #deb068;
  margin-right: 10px;
}

.changeLogContainer {
  margin: auto;
  background-color: #282c34;
  color: #e9546b;
  padding: 20px;
  border-radius: 10px;
  font-size: calc(10px + 2vmin);
  font-family: 'Arial', sans-serif;
}

.changeLogContainer h1 {
  text-align: center;
  margin-bottom: 40px;
  color: #89c3eb;
}

.changeItem {
  margin-bottom: 30px;
}

.changeItem h2 {
  color: #00a381;
  margin-bottom: 10px;
}

.changeItem ul {
  list-style-type: square;
  list-style-position: inside;
}

.changeItem ul li {
  margin-bottom: 5px;
  color: #deb068;
}

@media (min-width: 1024px) {
  .reelContainer {
    justify-content: center;
  }
  
  .reel {
    height: 30vw;
    width: 15vw;
  }
  
  .header {
    max-width: 1024px;
    margin: 0 auto;
  }
  
  .title {
    font-size: calc(30px + 4vmin);
  }
  
  .mode {
    font-size: calc(10px + 3vmin);
  }

  .symbol {
    font-size: calc(25px + 7vmin);
  }
  
  .button {
    font-size: calc(10px + 3vmin);
    height: calc(10px + 5vmin);
  }
  
  .lamp {
    font-size: 5vmin;
  }
  
  .newMedals {
    font-size: 4vmin;
  }
  
  .totalAndSpinCount {
    font-size: calc(10px + 3vmin);
  }
}
